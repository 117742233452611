<template>
    <div class="row">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Sign in</h5>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                </p>
                <Message v-if="showError" severity="error" :closable="false">
                    Incorrect login details</Message
                >

                <form @submit.prevent="submit">
                    <div class="p-field">
                        <h5>
                            Email address
                            <field-required-notification />
                        </h5>
                        <InputText
                            type="email"
                            class="form-input"
                            id="email"
                            name="email"
                            autocomplete="username"
                            v-model.trim="form.username"
                        />
                    </div>

                    <div class="p-field">
                        <h5>Password <field-required-notification /></h5>
                        <InputText
                            type="password"
                            class="form-input"
                            id="password"
                            name="password"
                            autocomplete="password"
                            v-model="form.password"
                        />
                    </div>

                    <div class="p-field">
                        <Button
                            type="submit"
                            click="submit"
                            class="btn btn-primary btn-lg form-input"
                            :disabled="!fieldsCompleted || loading"
                        >
                            <span v-if="loading"
                                ><i class="pi pi-spin pi-spinner"></i
                            ></span>
                            <span v-else>Sign in</span>
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import { mapActions } from "vuex"

export default {
    name: 'Login',
    data: () => {
        return {
            form: {
                username: '',
                password: ''
            },
            showError: false,
            loading: false
        }
    },
    computed: {
        fieldsCompleted() {
            if (this.form.username && this.form.password) {
                return true
            }
            return false
        }
    },
    methods: {
        ...mapActions(["logIn"]),
        async submit() {
            this.loading = true
            const User = new FormData()
            User.append("username", this.form.username)
            User.append("password", this.form.password)

            try {
                this.showError = false
                await this.logIn(User);
                this.$router.push(this.$store.getters.defineUserTypeHome)
            } catch (error) {
                this.showError = true
                this.loading = false
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-input {
    width: 100%;
}
.p-field {
    display: block;
    margin-top: 20px;
}
</style>
